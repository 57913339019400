<template>
  <div
    class="
      tags-container
      border-radius-6
      padding-20
      border-radius-6
      box-shadow-light-grey
      height-calc-type1
    "
  >
    <c-table
      :dataList="dataList"
      :rowList="rowList"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :isUseCheckbox="true"
      operationWidth="250"
      height="calc(100vh - 320px)"
      @pageChange="pageChange"
      @selectChange="selectChange"
      @handleEvent="handleEvent"
    >
      <template #filter>
        <el-button @click="initData" class="margin-right-ten"
          >刷新数据</el-button
        >
        <el-select v-model="status" style='width:100px' clearable class='margin-right-ten' placeholder="状态" @change="initData">
          <el-option :value='0' label='关闭'></el-option>
          <el-option :value='1' label='开启'></el-option>
        </el-select>
        <el-input
          placeholder="请输入标签信息"
          style="width: 210px"
          class="margin-right-ten"
          v-model="search"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </el-input>
        <!-- <el-button
          class="margin-right-ten"
          type="primary"
          size="default"
          @click="addTagGroup"
          >新建组</el-button
        >
        <el-button
          class="margin-right-ten"
          type="danger"
          @click="delGroup"
          :disabled="selection.length === 0"
          >批量删除</el-button
        > -->
      </template>
      <template #tagItems="{ row }">
        <div class="tags-collection">
          <div class="item-tags flex-row-start-start-wrap">
            <div class="item-tag" v-for="item in row.tagItems" :key="item.id">
              <span>{{ item.name }}</span>
              <i class="el-icon-error del-icon" @click="delTag(row,item)"></i>
            </div>
          </div>
          <div class="operation">
            <el-button
              type="primary"
              class="button-small"
              @click="addSingleTag(row)"
              size="small"
              >添加标签</el-button
            >
          </div>
        </div>
      </template>
      <template #buttonOperation="{ row }">
        <el-button
          type="primary"
          class="button-small"
          size="small"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button type="danger" class="button-small" @click="delItem(row)"
          >删除</el-button
        >
      </template>
    </c-table>
    <el-dialog
      width="800px"
      v-dialogDrag
      append-to-body
      :visible.sync="showDialog"
      :before-close="handleDialogClose"
    >
      <div slot="title">添加标签组(请点击需要选择的标签)</div>
      <div>
        <el-input
          v-model="name"
          placeholder="标签组名(必填)"
          style="width: 200px"
          class="margin-right-ten"
          clearable
        ></el-input>
        <el-input
          v-model="searchTagName"
          placeholder="请输入标签名"
          style="width: 250px"
          clearable
        ></el-input>
        <span style='margin-top:15px; margin-left:10px; font-weight:bold'>可供选择的标签项<span style='color:#fd5d5a'>(非必选)</span><i class='el-icon-info' style='color:#ccc;cursor:help' title='如果在新创建的标签组的过程中需要添加标签，可以在此进行勾选操作。'></i></span>
      </div>
      <div
        class="tags-list flex-row-start-start-wrap"
        v-if="tagsList.length > 0"
      >
        <span class="tag-item" @click='chooseSingleTag(item)' :class="{'is-active':singleTagList.includes(item.id)}" v-for="item in tagsList" :key="item.id">{{
          item.name
        }}</span>
      </div>
      <custom-icon v-else></custom-icon>
      <div class="button-list">
        <el-button type="primary" @click="handleOk" :loading="okLoaidng"
          >确定</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="添加标签"
      width="500px"
      v-dialogDrag
      append-to-body
      :visible.sync="showAddDialog"
      :before-close="handleDialogClose"
    >
      <div>
        <el-input
          v-model="searchAddTagName"
          placeholder="请输入标签名"
          style="width: 250px"
          clearable
        ></el-input>
      </div>
      <div
        class="tags-list flex-row-start-start-wrap"
        v-if="tagsListWithAdd.length > 0"
      >
        <span
          @click="chooseTag(item)"
          class="tag-item"
          :class="{ 'is-active': tagSelection.includes(item.id) }"
          v-for="item in tagsListWithAdd"
          :key="item.id"
          >{{ item.name }}</span
        >
      </div>
      <custom-icon v-else></custom-icon>
      <div class="button-list">
        <el-button type="primary" @click="Ok">确定</el-button>
        <el-button @click="NotOk">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTagGroupList,
  addTagGroup,
  editTagGroupStatus,
  editTagGroupOfTagItem,
  delUnbindTag,
  delTagGroup,
  getTagItemList,
  editTagGroup
} from '@/api/material'
import CustomIcon from './../icon.vue'
export default {
  data () {
    return {
      total: 0,
      addInfo: {},
      page: 1,
      name: '',
      curInfo: null,
      options: [],
      pageSize: 10,
      selection: [],
      loading: false,
      showDialog: false,
      searchTagName: '',
      showAddDialog: false,
      searchAddTagName: '',
      okLoaidng: false,
      search: '',
      status: null,
      singleTagList: [],
      tagSelection: [], //* 新增标签选中的标签项
      copyTagsList: [],
      tagsList: [],
      tagsListWithAdd: [], // *针对添加标签 数据与tagsList进行区分
      dataList: [],
      isCantDelTag: false, // 针对新增标签 对已经存在的标签不能进行删除
      rowList: [
        {
          label: '标签组',
          prop: 'tagGroupName'
        },
        {
          label: '标签',
          prop: 'tagItems',
          slot: true,
          width: 300
        },
        {
          label: '当前状态',
          prop: 'status',
          switch: true,
          width: 150
        }
      ]
    }
  },
  mounted () {
    this.initData()
  },
  watch: {
    searchTagName (newV) {
      if (newV) {
        this.handleSearch('group')
      } else {
        this.tagsList = this.copyTagsList
      }
    },
    searchAddTagName (newV) {
      if (newV) {
        this.handleSearch('single')
      } else {
        const alreadySelectTagItems = this.curInfo?.tagItems ? this.curInfo.tagItems.map(item => item.id) : []
        this.tagsListWithAdd = this.copyTagsList.filter(item => !alreadySelectTagItems.includes(item.id))
      }
    }
  },
  methods: {
    initData () {
      this.loading = true
      getTagGroupList({
        page: this.page,
        pageSize: this.pageSize,
        tagGroupName: this.search,
        status: this.status
      })
        .then((res) => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    addTagGroup () {
      getTagItemList({
        page: 1,
        pageSize: 999,
        tagItemName: ''
      }).then((res) => {
        this.tagsList = res.list
        this.tagsListWithAdd = res.list
        this.copyTagsList = JSON.parse(JSON.stringify(this.tagsList))
        this.showDialog = true
      })
    },
    chooseTag (val) {
      const targetIndex = this.tagSelection.findIndex(
        (item) => item === val.id
      )
      if (targetIndex > -1) {
        this.tagSelection.splice(targetIndex, 1)
      } else {
        this.tagSelection.push(val.id)
      }
    },
    chooseSingleTag (val) {
      const targetIndex = this.singleTagList.findIndex(
        (item) => item === val.id
      )
      if (targetIndex > -1) {
        this.singleTagList.splice(targetIndex, 1)
      } else {
        this.singleTagList.push(val.id)
      }
    },

    delGroup () {
      const ids = this.selection.map((item) => item.id)
      this.$confirm('确定删除吗？删除的文件将无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTagGroup(ids).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    delTag (row, val) {
      delUnbindTag(row.id, val.id).then(() => {
        this.$message.success('解绑成功!')
        this.initData()
      })
    },
    delItem (row) {
      this.$confirm('确定删除吗？删除的文件将无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTagGroup(row.id).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    handleEvent ({ info, val }) {
      editTagGroupStatus(info.id, val).then(() => {
        this.$message.success('操作成功！')
        this.initData()
      })
    },
    handleSearch (type = 'group') {
      if (type === 'group') {
        this.tagsList = this.copyTagsList.filter((item) =>
          item.name.includes(this.searchTagName)
        )
      } else {
        const alreadySelectTagItems = this.curInfo.tagItems ? this.curInfo.tagItems.map(item => item.id) : []
        this.tagsListWithAdd = this.copyTagsList.filter(item => !alreadySelectTagItems.includes(item.id) && item.name.includes(this.searchAddTagName))
      }
    },
    addSingleTag (row) {
      getTagItemList({
        page: 1,
        pageSize: 999,
        tagItemName: ''
      }).then((res) => {
        this.tagsList = res.list
        this.tagsListWithAdd = res.list
        this.copyTagsList = JSON.parse(JSON.stringify(this.tagsList))
        this.showAddDialog = true
        this.curInfo = row
        const alreadySelectTagItems = this.curInfo.tagItems ? this.curInfo.tagItems.map(item => item.id) : []
        this.tagsListWithAdd = this.copyTagsList.filter(item => !alreadySelectTagItems.includes(item.id))
        if (this.tagsListWithAdd.length === 0) {
          this.$message.warning('没有多余的标签可供选择!')
        }
      })
    },
    handleDialogClose () {
      this.showDialog = false
      this.showAddDialog = false
    },

    handleEdit (row) {
      this.$prompt('标签组名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        editTagGroup(row.id, {
          tagGroupName: value
        }).then(() => {
          this.$message.success('修改成功!')
          this.initData()
        })
        // editTagGroupName(row.id, value).then(() => {
        //   this.$message.success('修改成功!')
        //   this.initData()
        // })
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    selectChange (val) {
      this.selection = val
    },
    handleOk () {
      this.okLoaidng = true
      addTagGroup({
        tagGroupName: this.name,
        tagItemIds: this.singleTagList.length > 0 ? this.singleTagList : null
      })
        .then(() => {
          this.$message.success('新增成功!')
          this.name = ''
          this.initData()
        })
        .finally(() => {
          this.okLoaidng = false
          this.cancel()
        })
    },
    cancel () {
      this.showDialog = false
      this.resetData()
    },
    Ok () {
      if (this.tagSelection.length === 0) { return this.$message.warning('请勾选标签！') }
      editTagGroupOfTagItem(this.curInfo.id, this.tagSelection).then(() => {
        this.$message.success('操作成功!')
        this.initData()
        this.showAddDialog = false
        this.resetData()
      })
    },
    NotOk () {
      this.showAddDialog = false
      this.resetData()
    },
    resetData () {
      this.curInfo = null
      this.tagSelection = []
      this.isCantDelTag = false
      this.searchAddTagName = ''
      this.searchTagName = ''
      this.singleTagList = []
      this.name = ''
    }
  },
  components: {
    CustomIcon
  }
}
</script>
<style lang='scss' scoped>
.tags-container {
  background: #fff;
}
.tags-list {
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
  .tag-item {
    margin: 0 10px 20px 0;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      color: #00bf8a;
      border-color: #00bf8a;
    }
  }
  .is-active {
    color: #fff !important;
    background-color: #00bf8a;
    border-color: #00bf8a;
  }
}
.tags-collection {
  max-height: 92px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .item-tags {
    position: relative;
    width: 80%;
    padding-top:5px;
    .item-tag {
      font-size: 12px;
      margin: 0 10px 10px 0;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;

      position: relative;
      transition: all 0.3s;
      .del-icon {
        position: absolute;
        top: -15%;
        left: 90%;
        font-size: 14px;
        display: none;
        color: #fd5d5a;
         cursor: pointer;
      }
      &:hover {
        border-color: #fd5d5a;
        color: #fd5d5a;
        .del-icon {
          display: block;
        }
      }
    }
  }
  .operation {
    position: absolute;
    left: 80%;
    top: 10%;
  }
}
.button-list {
  margin-top: 20px;
  text-align: center;
}
</style>
